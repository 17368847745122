import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  initialize() {
    this.onDragStart = this.onDragStart.bind(this)
    this.onDragEnd = this.onDragEnd.bind(this)

    this.element.setAttribute('draggable', 'true')

    super.initialize()
  }

  connect() {
    this.element.addEventListener('dragstart', this.onDragStart)
    this.element.addEventListener('dragend', this.onDragEnd)

    super.connect()
  }

  disconnect() {
    this.element.removeEventListener('dragstart', this.onDragStart)
    this.element.removeEventListener('dragend', this.onDragEnd)

    super.disconnect()
  }

  onDragStart(event) {
    this.offsetX = event.offsetX
    this.offsetY = event.offsetY
  }

  onDragEnd(event) {
    this.element.style.left = `${this.offsetX}px`
    this.element.style.top = `${this.offsetY}px`

    this.offsetX = null
    this.offsetY = null
  }
}
