import { Controller } from "@hotwired/stimulus"
import interact from "interactjs"
import { get } from "@rails/request.js"

import {useToastNotifiers} from "../mixins/useToastNotifiers"

export default class extends Controller {
  static targets = ["icon", "dropzone"]
  static values = {
    replace: String,
    line: String,
    connect: String,
    branch: String,
    data: String,
  }

  initialize() {
    super.initialize()
    this.onDrop = this.onDrop.bind(this)
    this.onDragEnter = this.onDragEnter.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDropDeactivate = this.onDropDeactivate.bind(this)

    this.element.id ||= crypto.randomUUID()
  }

  connect() {
    let dropzone = this.element

    if (this.hasDropzoneTarget) {
      dropzone = this.dropzoneTarget
    }

    interact(dropzone).dropzone({
      ondrop: this.onDrop,
      ondragenter: this.onDragEnter,
      ondragleave: this.onDragLeave,
      ondropdeactivate: this.onDropDeactivate,
    })

    useToastNotifiers(this)
  }

  onDrop({ relatedTarget }) {
    const stepType = relatedTarget.dataset.kind

    if (stepType === "wait") {
      if (this.parentIsWaitStep || this.childIsWaitStep) {
        relatedTarget.style?.removeProperty("transform")
        this.showToastErrorMessage(this.element.dataset.waitErrorMessage)
        return
      }
    }

    if (stepType === "stop") {
      if (this.parentIsStopStep || this.childIsStopStep) {
        console.log(this.element.dataset)
        relatedTarget.style?.removeProperty("transform")
        this.showToastErrorMessage(this.element.dataset.stopErrorMessage)
        return
      }
    }

    this.getStepFromServer(relatedTarget)
  }

  onDragEnter() {
    this.iconTarget.classList.remove("hidden")
  }

  onDragLeave() {
    this.iconTarget.classList.add("hidden")
  }

  onDropDeactivate() {
    this.iconTarget.classList.add("hidden")
  }

  async getStepFromServer(relatedTarget) {
    const url = relatedTarget.parentElement.dataset["journeys-StepUrlValue"]

    const response = await get(url, {
      responseKind: "turbo-stream",
      query: {
        after: this.replaceValue || this.element.id,
        parent_id: this.lineValue,
        wait:
          this.element.closest("[data-step='wait']")?.contains(this.element) ||
          "",
        branch: this.branchValue,
        connect: this.connectValue,
        trigger_id: window.triggerId,
        channel_trigger: window.triggerIsChannel,
        child: this.element.dataset.child || "",
        data: this.dataValue,
        nested:
          this.element
            .closest("[data-step='condition']")
            ?.contains(this.element) || "",
      },
    })

    relatedTarget.style?.removeProperty("transform")
    window.dispatchEvent(
      new CustomEvent("step:show", { bubbles: true, cancelable: true })
    )
  }

  syncTrigger({ detail }) {
    this.triggerId = detail
  }

  // private

  get parentIsWaitStep() {
    return (
      this.element.previousElementSibling.dataset.controller === "step--wait"
    )
  }

  get parentIsStopStep() {
    return (
      this.element.previousElementSibling.dataset.controller === "step--stop"
    )
  }

  get childIsWaitStep() {
    return (
      document.getElementById(this.element.dataset.child)?.dataset
        .controller === "step--wait"
    )
  }

  get childIsStopStep() {
    return (
      document.getElementById(this.element.dataset.child)?.dataset
        .controller === "step--stop"
    )
  }
}
