import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    const container = this.element
    const content = container.innerHTML

    // Create two copies for seamless loop
    container.innerHTML = content + content

    // Calculate animation duration based on single content width
    const singleWidth = container.scrollWidth / 2 // divide by 2 since we doubled the content
    const pixelsPerSecond = 50
    const duration = singleWidth / pixelsPerSecond

    // Apply dynamic animation
    container.animate(
      [
        { transform: 'translateX(0)' },
        { transform: `translateX(-${singleWidth}px)` },
      ],
      {
        duration: duration * 1000, // convert to milliseconds
        iterations: Infinity,
        easing: 'linear',
      }
    )
  }
}
