import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

// attaches an infinite-scroll listener on either a specific scrollable element or
// the entire window(<body>)

// if you want to attach the listener to the window, you can do this
// data-infinite-scroll-attach-to-body-value="true", then the listener will be attached on the window
// rather than the element.

// If you omit this option, the controller will attach the event listener-
// on the element that has the declaration
//<element data-controller="infinite-scroll">

// make sure that there is <element data-infinite-scroll-target="next"> for the controller to work correctly.

export default class extends Controller {
  static targets = ['next']
  static values = {
    threshold: Number,
    attachToBody: Boolean,
  }

  initialize() {
    this.bodyScrollListener = this.bodyScrollListener.bind(this)
    this.scroll = this.scroll.bind(this)
  }

  connect() {
    if (this.attachToBodyValue) {
      document.addEventListener('scroll', this.bodyScrollListener)
      return
    }

    this.element.addEventListener('scroll', this.scroll)
  }

  scroll() {
    if (this.scrollReachedEnd && this.hasNextTarget) {
      this.nextTarget.click()
    }
  }

  disconnect() {
    document.removeEventListener('scroll', this.bodyScrollListener)
    this.element.removeEventListener('scroll', this.scroll)

    super.disconnect()
  }

  bodyScrollListener() {
    if (this.reachedEndOfBody && this.hasNextTarget) {
      this.nextTarget.click()
    }
  }

  get reachedEndOfBody() {
    return (
      document.documentElement.scrollTop >=
      document.documentElement.scrollHeight - document.body.offsetHeight - 100
    )
  }

  get scrollReachedEnd() {
    return (
      this.element.scrollTop >=
      this.element.scrollHeight - this.element.offsetHeight - 100
    )
  }
}
