import GroupController from '../filter/group_controller'

export default class extends GroupController {
  static values = {
    answers: Array,
  }

  static targets = [
    'submit',
    'limitNotice',
    'details'
  ]

  connect() {
    this.detailsTarget.addEventListener('toggle', ({ target }) => {
      if(target.open) {
        const lastItem = this.itemTargets[this.itemTargets.length - 1]

        const input = lastItem.querySelector('input')
        this.focus(input, { moveCursorToEnd: true })
      }
    })

    super.connect()
  }

  update({ detail: answersWithIds }) {
    this.answersValue = answersWithIds

    this.itemTargets.forEach((item, index) => {
      item.setAttribute('data-id', answersWithIds[index].id)
    })
  }

  onInputEnter({ target }) {
    if(!target.value || this.limitReached) return

    const parent = target.closest('[data-automation--answers-target="item"]')
    const lastItem = this.itemTargets[this.itemTargets.length - 1]

    if(parent === lastItem) {
      this.addNewItem()
    }
  }

  onFilterItemChange({ target }) {
    if(this.limitReached) {
      return this.hide(this.orValueButtonTarget)
    }

    const parent = target.closest('[data-automation--answers-target="item"]')
    const lastItem = this.itemTargets[this.itemTargets.length - 1]

    if(parent === lastItem) {
      if(target.value) {
        this.show(this.orValueButtonTarget)
      } else {
        this.hide(this.orValueButtonTarget)
      }
    }

    if(this.answersValue.length === 0) {
      this.answersValue = [
        {
          id: parent.dataset.id,
          body: target.value,
        }
      ]
    } else {
      this.answersValue = this.answersValue.map((value, index) => {
        if(index === this.itemTargets.indexOf(parent)) {
          return {
            id: parent.dataset.id,
            body: target.value,
          }
        } else {
          return value
        }
      })
    }
  }

  addNewItem() {
    if(this.limitReached) return

    const lastItem = this.itemTargets[this.itemTargets.length - 1]
    const newItem = this.templateTarget.cloneNode(true)

    newItem.querySelector('label').textContent = t.automation.answer.label.replace("%{count}", this.itemTargets.length + 1)

    newItem.setAttribute('data-automation--answers-target', 'item')
    newItem.setAttribute('data-controller', 'filter--group-item')

    newItem.classList.remove('hidden')
    newItem.querySelectorAll('input, select').forEach(this.enable)

    this.listTarget.insertBefore(newItem, this.templateTarget)

    this.nextTick(() => {
      this.focus(newItem.querySelector('input'))
    })

    lastItem.querySelector("[data-filter--group-item-target='removeButtonContainer']").classList.remove('hidden')

    this.answersValue = [
      ...this.answersValue,
      {
        body: "",
      }
    ]
  }

  removeItem({ currentTarget }) {
    const item = currentTarget.closest('[data-automation--answers-target="item"]')

    this.answersValue = this.answersValue.filter((_, index) => {
      return index !== this.itemTargets.indexOf(item)
    })

    item.remove()
  }

  itemTargetDisconnected() {
    super.itemTargetDisconnected()

    this.itemTargets.forEach((item, index) => {
      item.querySelector('label').textContent = t.automation.answer.label.replace("%{count}", index + 1)
    })

    this.hide(this.limitNoticeTarget)
  }

  itemTargetConnected() {
    if(this.limitReached) {
      this.hide(this.orValueButtonTarget)
      this.show(this.limitNoticeTarget)
    } else {
      this.hide(this.limitNoticeTarget)
    }

    if(this.itemTargets.length === 1) {
      this.itemTargets[0].querySelector("[data-filter--group-item-target='removeButtonContainer']").classList.add('hidden')
    } else {
      this.itemTargets.forEach(item => {
        item.querySelector("[data-filter--group-item-target='removeButtonContainer']").classList.remove('hidden')
      })
    }
  }

  answersValueChanged() {
    const valid = !(this.answersValue.length === 0 || (this.answersValue.length === 1 && this.answersValue[0] === ""))

    this.dispatch('validation', {
      target: this.element,
      detail: {
        valid,
        answers: this.answersValue,
      }
    })
  }
}
