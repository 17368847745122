import { get } from '@rails/request.js'

import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    url: String,
  }

  static targets = [
    'paginationFrame',
    'previousPaginationFrame',
    'event',
    'scroll',
    'page',
    'timestamp',
    'unifyTimestamp',
  ]

  initialize() {
    this.loadedPages = []

    this.scrollToBottom()
    this.onScroll = this.onScroll.bind(this)

    super.initialize()
  }

  connect() {
    this.element.addEventListener('scroll', this.onScroll)
    super.connect()
  }

  disconnect() {
    this.element.removeEventListener('scroll', this.onScroll)
    super.disconnect()
  }

  scrollToBottom() {
    this.manualScrolling = true

    this.element.scroll({
      top: this.element.scrollHeight,
      behavior: this.messageSent ? "smooth" : "instant",
    })

    this.manualScrolling = false
  }

  onScroll() {
    if(this.manualScrolling) return

    this.oldBottomScrollPosition = this.bottomScrollPosition

    if(this.hasPaginationFrameTarget && this.element.scrollTop < 200 && !this.fetchingNewPage && !this.hasScrollTarget) {
      this.fetchingNewPage = true

      this.paginationFrameTarget.classList.remove('hidden')
      this.paginationFrameTarget.classList.add('bottom-5', 'left-5', 'absolute')
    }

    const bottomScrollPosition = Math.abs(this.element.scrollTop - (this.element.scrollHeight - this.element.clientHeight))

    if(this.hasPreviousPaginationFrameTarget && bottomScrollPosition < 400 && !this.hasScrollTarget) {
      this.previousPaginationFrameTarget.classList.remove('hidden')
      this.previousPaginationFrameTarget.classList.add('bottom-5', 'left-5', 'absolute')
    }
  }

  paginationFrameTargetConnected(target) {
    this.fetchingNewPage = false

    if(this.hasScrollTarget) {
      this.manualScrolling = true

      const value = this.scrollTarget.dataset.value
      this.scrollEventIntoView({ detail: value })

      this.nextTick(() => {
        this.manualScrolling = false
      })
    } else if(this.oldScrollHeight) {
      this.element.scroll({
        top: this.element.scrollHeight - this.oldScrollHeight,
        behavior: 'instant',
      })
    }

    this.oldScrollHeight = this.element.scrollHeight

    const url = new URL(target.getAttribute('src'))
    url.searchParams.set('loaded', this.loadedPages.join(','))

    target.setAttribute('src', url.toString())

    this.nextTick(() => {
      const page = parseInt(target.dataset.page)

      if(this.loadedPages.includes(page)) {
        target.remove()
      }
    })
  }

  previousPaginationFrameTargetConnected(target) {
    this.paginationFrameTargetConnected(target)
  }

  scrollEventIntoView({ detail: eventId }) {
    const elementId = `conversation_${eventId}`
    const eventElement = this.eventTargets.find((element) => element.id === elementId)

    if(eventElement) {
      this.manualScrolling = true

      eventElement.classList.replace('text-night-60', 'text-night')
      eventElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })

      return this.nextTick(() => {
        this.manualScrolling = false

        if(this.hasScrollTarget) {
          this.scrollTarget.remove()
        }

        eventElement.classList.replace('text-night', 'text-night-60')
      }, 500)
    } else if(eventId.includes('message')) {
      return this.dispatch('focus-campaign-message', {
        detail: eventId
      })
    }

    const recordId = eventId.split('_').pop()
    const url = this.urlValue.replace(':event', recordId)

    get(url, {
      responseKind: 'turbo-stream',
      query: {
        loaded: this.loadedPages.join(',')
      }
    })
  }

  pageTargetConnected(target) {
    this.loadedPages = Array.from(new Set([...this.loadedPages, parseInt(target.dataset.value)]))
    target.remove()

    this.paginationFrameTargets.forEach((frame) => {
      const url = new URL(frame.getAttribute('src'))
      url.searchParams.set('loaded', this.loadedPages.join(','))

      frame.setAttribute('src', url.toString())
    })

    this.previousPaginationFrameTargets.forEach((frame) => {
      const url = new URL(frame.getAttribute('src'))
      url.searchParams.set('loaded', this.loadedPages.join(','))

      frame.setAttribute('src', url.toString())
    })
  }

  unifyTimestampTargetConnected() {
    this.unifyTimestampTarget.remove()
    this.removeDuplicateTimestamps()
  }

  removeDuplicateTimestamps() {
    let timestamps = this.timestampTargets

    timestamps.forEach((timestamp) => {
      timestamps
        .filter(element => element.id === timestamp.id && element !== timestamp)
        .forEach(element => element.remove())

      timestamps = timestamps.filter(element => element !== timestamp)
    })
  }

  eventTargetConnected(target) {
    if(target.hasAttribute('data-scroll-into-view') && this.oldBottomScrollPosition < 10) {
      target.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }

  get bottomScrollPosition() {
    return Math.abs(this.element.scrollTop - (this.element.scrollHeight - this.element.clientHeight))
  }
}
