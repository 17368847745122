import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static targets = ["hidden", "allTime", "overTheLast", "durationInput"]
  static classes = ["selected"]

  showPeriodFilters() {
    this.element.querySelectorAll('input, select').forEach(this.enable)
    this.hiddenTargets.forEach((element) => {
      this.show(element)
    })

    this.show(this.durationInputTarget)
    this.enable(this.durationInputTarget)

    this.toggleSelectedClass()
  }

  hidePeriodFilters() {
    this.element.querySelectorAll('input, select').forEach(this.disable)
    this.hiddenTargets.forEach((element) => {
      this.hide(element)
    })

    this.hide(this.durationInputTarget)
    this.disable(this.durationInputTarget)

    this.toggleSelectedClass()
  }

  // private

  toggleSelectedClass() {
    if(this.hasSelectedClass) {
      this.allTimeTarget.classList.toggle(this.selectedClasses)
      this.overTheLastTarget.classList.toggle(this.selectedClasses)
    }
  }
}
