import ComposeController from './compose/base_controller'

import {
  KeyboardKey,
  Piece,
  TagsManager,
  LinksManager,
  Coupon,
  Contact
} from './compose/models'

import data from '@emoji-mart/data'
import { init, SearchIndex } from 'emoji-mart'

export default class extends ComposeController {
  static targets = [
    'inputContainer',
    'templatesIcon',
    'templatesMenu',
    'templateIdInput',
    'toolbar',
    'technologies',
    'noteToolIcon',
    'couponIdInput',
    'focus',
    'linkInputsList',
    'disableNotice',
    'mediaContainer',
    'mediaContainerList',
    'voiceContainer',
    'locationContainer',
    'footer',
    'mediaAttachmentTool',
  ]

  static values = {
    allowedProfileTags: Array,
    allowedCouponTags: Array,
    name: String,
    technology: Object,
    window: Object,
    submitOnEnter: { type: Boolean, default: false },
    editTemplateUrl: String,
    footer: String,
    latitude: Number,
    longitude: Number,
    smsOnly: Boolean,
  }

  initialize() {
    super.initialize()

    this.state = {
      templatesVisible: false,
      links: {}
    }

    this.templatesToolMutationObserver = new MutationObserver(this.templatesToolMutationObserverCallback.bind(this))

    this.tagsManager = new TagsManager(this)
    this.linksManager = new LinksManager(this)

    this.attachments = Array.from(this.element.querySelectorAll('[data-controller="editor--attached-media"]')) || []
  }

  connect() {
    init({ data })

    super.connect()

    this.tagsManager.setup()

    if (this.hasTemplatesMenuTarget) {
      this.templatesToolMutationObserver.observe(this.templatesMenuTarget, {attributes: true})
    }

    if(this.autoFocusValue) {
      // When the user clicks on the conversation card on the Inbox, the container is focused but no cursor is visible
      // This way we force the cursor to show itself.

      this.trixTarget.blur()
      this.focus()
    }

    if(this.inTemplateCreationMode && this.smsOnlyValue) {
      this.nextTick(() => {
        if(this.hasFooterTarget) {
          this.dispatch('footer:disable', {
            target: this.footerTarget
          })
        }

        this.dispatch('toolbar:disable', {
          target: this.element,
          detail: ['attachment', 'voice', 'location', 'button']
        })
      })
    }
  }

  disconnect() {
    this.templatesToolMutationObserver.disconnect()
    super.disconnect()
  }

  onClick({target}) {
    if (target === this.inputContainerTarget) {
      this.focus()
    }
  }

  onWindowClick({target}) {
    if(!this.element.contains(target) && document.activeElement === this.trixTarget) {
      this.forceBlur()
    }
  }

  // Focus and Blur Methods

  onTrixSelectionChange() {
    if (this.restoringSelection) return
    super.onTrixSelectionChange()
  }

  focus() {
    if (!this.editor) return

    if (this.trixPosition) {
      this.restoreSelectionTo(this.trixPosition)
    }

    this.trixTarget.focus()
  }

  focusAndMoveToEnd() {
    if (!this.editor) return

    this.cursor.moveToEnd()
    this.focus()
  }

  onTrixFocus() {
    if (this.isBlurring) return

    // Give Trix time to load the updated content before we do this
    this.nextTick(() => this.cursor.ensureCaretVisibility())
  }

  forceBlur() {
    // When the user clicks a button in the toolbar, trix will blur, but the cursor will still be visible,
    // allowing the user to type. This way, we force Trix to blur.
    const input = window.document.createElement('input')
    input.classList.add('absolute', '-left-[20rem]', '-top-[20rem]')

    document.body.appendChild(input)

    input.focus()
    input.remove()
  }

  onTrixBlur() {
    const documentString = this.trixDocument.toString()

    if ((documentString.length === 2 && documentString.charCodeAt(0) === 8204)) {
      this.isBlurring = true

      this.resetContent()
      this.nextTick(() => {
        this.isBlurring = false
      })

      return this.trixTarget.blur()
    }
  }

  // Focus and Blur Methods

  restoreSnapshot({detail: snapshot}) {
    if(snapshot) {
      this.editor.loadSnapshot(snapshot.trixSnapshot)

      if (this.technologies) {
        this.technologies.restoreSnapshot(snapshot)
      }

      this.footerValue = snapshot.footer
      this.dispatch('footer:restore', {
        target: this.footerTarget,
        detail: snapshot.footer,
      })

      this.dispatch('map:restore', {
        target: this.locationContainerTarget,
        detail: {
          latitude: snapshot.location?.lat,
          longitude: snapshot.location?.lng,
        },
      })

      this.dispatch('buttons:restore', {
        detail: snapshot.buttons,
        target: this.element.closest('[data-controller*="compose--template"]')
      })

      this.nextTick(() => {
        this.cursor.moveToEnd()
      })
    }

    this.dispatch('templates:toggle', {
      target: this.templatesMenuTarget,
      detail: this.state.templatesVisible = false
    })
  }

  onTrixBeforePaste(event) {
    if (event.paste.hasOwnProperty('html')) {
      let div = document.createElement("div");
      div.innerHTML = event.paste.html;
      event.paste.html = div.textContent;
    }
  }

  onTrixPaste(event) {
    this.nextTick(() => {
      this.onTrixContentChange()

      const documentLength = this.trixDocument.toString().length
      const {range: [_, end]} = event.paste

      if (documentLength === end) {
        // When pasting the content and the document starts with a zero width non joiner space,
        // Cursor will not be moved to the end of pasted content correctly. Because the zero
        // width non joiner space Is removed before the onTrixPaste event is triggered
        // inside onTrixContentChange.
        this.cursor.moveTo(end - 1)
      } else {
        this.cursor.moveTo(end)
      }
    })
  }

  onTrixInitialize() {
    super.onTrixInitialize()

    this.nextTick(() => {
      if (this.hasContent) {
        this.cursor.moveToEnd()
      }
    })

    if(this.disabledWhatsapp) {
      this.disable()
    }

    this.dispatchInitializeEvent()
    this.attachEventListenersToFigures()

    this.savePositionAfterContentLoad()
  }

  savePositionAfterContentLoad() {
    this.nextTick(this.onTrixSelectionChange, 10)
  }

  onTrixKeydown(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if(this.hasReachedMaxMessageLength && (this.inTemplateCreationMode || !key.isEnter) && !key.isBackspace && !key.hasAnyModifier) {
      return event.preventDefault()
    }

    this.syncTagsToolActiveStatus()

    if (key.isHotkey) {
      return this.handleHotkey(event)
    }

    if (key.isBackspace && this.cursor.pieceBeforeCaret().isShortlink) {
      return this.linksManager.deleteAttachmentInPosition()
    }

    if (key.isArrow) {
      return this.handleArrowNavigation(event)
    }

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceBeforeCaretIsShortLink()) {
      this.ensureWhitespaceBeforeCursor()
    }

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceBeforeCaretIsTag()) {
      this.ensureWhitespaceBeforeCursor()
    }
  }

  onTrixKeyup(event) {
    this.syncTagsToolActiveStatus()
    this.syncTemplatesMenuStatus()

    const key = KeyboardKey.fromKeyboardEvent(event)

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceAtCaretIsShortLink()) {
      this.ensureWhitespaceAfterCursor()
    }

    if (this.noSelection && key.isAlphanumeric && this.cursor.pieceAtCaretIsTag()) {
      this.ensureWhitespaceAfterCursor()
    }
  }

  handleHotkey(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if(key.activateBold || key.activateItalic && this.cursor.pieceAtCaretIsTag()) {
      event.preventDefault()
    }

    if (key.activateLink) {
      this.toggleLinkTool()
    }

    if (key.isShiftEnter) {
      event.preventDefault()
      this.editor.insertLineBreak()
      this.cursor.moveToRight()
    }

    if (key.isEnter && this.hasContent && !key.isShift && !this.state.templatesVisible) {
      event.preventDefault()
      this.submit()
    }

    if(key.isPasting) {
      if(this.cursor.pieceBeforeCaretIsTag()) {
        const characterAtCaret = this.cursor.characterAtCaret()

        if(characterAtCaret.isLineFeed || !characterAtCaret.isWhitespace) {
          this.insertWhitespace()
          this.cursor.moveToRight(false)
        }

        if(characterAtCaret.isWhitespace) {
          this.cursor.moveToRight(false)
        }
      }
    }
  }

  handleArrowNavigation(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if (key.isLeftArrow && this.cursor.pieceBeforeCaretIsShortLink()) {
      this.cursor.moveToLeft()
    } else if (key.isRightArrow && this.cursor.pieceAtCaretIsShortLink()) {
      this.cursor.moveToRight()
    }
  }

  onTrixContentChange() {
    if (this.isBlurring) return

    this.dispatch('content:change', {
      detail: this.snapshot
    })

    if (this.hasTrixJsonInputTarget) {
      this.trixJsonInputTarget.value = JSON.stringify(this.editor)
    }

    this.attachEventListenersToFigures()

    if (this.isParsing) return

    this.isParsing = true

    const trixPosition = this.trixPosition

    this.cursor.ensureCaretVisibility()

    if (this.empty) return

    if (this.documentStartsWithZeroWidthNonJoinerSpace) {
      this.deleteStartingZeroWidthNonJoinerSpace()
    }

    this.parser.tokens.forEach((token) => {
      this.applyTagExtensionForWordObject(token, trixPosition)
    })

    this.isParsing = false
  }

  applyTagExtensionForWordObject(token, trixPosition) {
    this.isParsing = true

    if(token.valid && !this.editor.attributeIsActive('background')) {
      // When pasting content, Trix does not apply the styles on valid tags.
      // Doing so on the next tick fixes this issue.
      this.nextTick(() => {
        if(token.shouldAddWhitespaceBefore) {
          this.editor.setSelectedRange(token.start)
          this.insertWhitespace()
          return this.nextTick(() => {
            this.editor.setSelectedRange(token.end + 1)
          })
        }

        this.editor.setSelectedRange(token.range)
        this.tagsManager.activate()
        this.editor.setSelectedRange(trixPosition)
      })
    }

    if(token.invalid) {
      this.editor.setSelectedRange(token.range)
      this.tagsManager.deactivate()
      this.editor.setSelectedRange(trixPosition)
    }

    this.isParsing = false
  }

  removeInvalidTagStyles() {
    const pieces = this.trixDocument.getPieces()

    pieces.forEach((p, index) => {
      const piece = Piece.wrap(p)

      if (piece.isTag && !Contact.isValidTag(piece.string) && !Coupon.isValidTag(piece.string)) {
        const rangeStart = this.contentLengthBeforePiece(index)
        const rangeEnd = rangeStart + parseInt(piece.string.length)

        const [currentStart, _] = this.editor.getSelectedRange()
        this.editor.setSelectedRange([rangeStart, rangeEnd].map((i) => parseInt(i)))

        this.isParsing = true

        this.tagsManager.deactivate()
        this.editor.setSelectedRange(currentStart)

        this.isParsing = false
      }
    })

    this.onTrixContentChange()
  }

  //  Link Tool and Link Edit methods

  onLinkEditCancellation() {
    // When a link is selected for being edited, Trix updated the position in onSelectionChange.
    // In the case the user cancels the edit, we need to restore the position to the previous one.
    // If we focus the trixPosition, Trix will focus next to the link, instead of the correct position.

    this.editor.setSelectedRange(this.oldPosition)
    this.focus()
  }

  handleLinkReceived(event) {
    const linkPartial = event.detail.linkPartial
    const htmlFromPartial = new DOMParser().parseFromString(linkPartial, 'text/html')

    const element = htmlFromPartial.querySelector('[data-sgid]')
    const {destination: url, id} = element.dataset

    this.state.links[id] = {url}

    this.linksManager.insert(event)
  }

  afterAttachmentInsertion() {
    this.attachEventListenersToFigures()
  }

  replaceLink(event) {
    this.linksManager.replace(event)
  }

  afterAttachmentReplacement(id) {
    delete this.state.links[id]
  }

  attachEventListenersToFigures() {
    this.nextTick(() => {
      this.trixTarget.querySelectorAll('figure').forEach(figure => {
        figure.removeEventListener('click', this.onFigureClicked.bind(this))
        figure.addEventListener('click', this.onFigureClicked.bind(this))

        const { id, url } = JSON.parse(figure.dataset.trixAttachment)

        this.state.links[id] = {url}

        console.log(JSON.parse(figure.dataset.trixAttachment))
      })
    })
  }

  //  Link Tool and Link Edit methods

  onFigureClicked(event) {
    event.preventDefault()
    event.stopPropagation()

    const figure = event.currentTarget
    const element = figure.firstElementChild

    let { link: clientUrl, id } = JSON.parse(figure.dataset.trixAttachment)

    const controller = this.application.getControllerForElementAndIdentifier(this.linkEditTool, 'link--edit')

    if (id in this.state.links) {
      clientUrl = this.state.links[id].url
    } else {
      this.state.links[id] = {
        url: clientUrl
      }
    }

    const detail = {
      figureElement: figure,
      linkElement: element,
      clientUrl,
      id
    }

    controller.editLink({detail})
  }

  onLinkUpdated({detail}) {
    const {id, url} = detail
    this.state.links[id] = {url}

    this.editor.setSelectedRange(this.oldPosition)
    this.focus()

    this.linkInputsListTarget.innerHTML = ''

    Object.entries(this.state.links).forEach(([id, {url}], index) => {
      const object = { id, url }

      Object.entries(object).forEach(([key, value]) => {
        const inputElement = document.createElement('input')
        inputElement.type = 'hidden'
        inputElement.name = `${this.nameValue}[links][${index}][${key}]`
        inputElement.value = value

        this.linkInputsListTarget.appendChild(inputElement)
      })
    })
  }


  insertTag(event) {
    this.tagsManager.insert(event)
  }

  toggleLinkTool() {
    this.element.querySelector(`[data-tool='link'] [data-cancel-button]`).click()
  }

  toggleVCardTool() {
    this.element.querySelector(`[data-tool='vcard'] [data-launcher]`).click()
  }

  showNoteForm() {
    this.noteToolIconTarget.click()
  }

  syncTagsToolActiveStatus() {
    const eventName = this.editor.attributeIsActive('background') ? 'toolbar:disable' : 'toolbar:enable'

    this.dispatch(eventName, {
      target: this.element,
      detail: ['tag', 'link', 'coupon']
    })
  }

  // Templates Tool Methods

  templatesToolMutationObserverCallback(mutations) {
    mutations.forEach((mutation) => {
      if (mutation.type === 'attributes') {
        this.state.templatesVisible = mutation.target.hasAttribute('data-visible')
        this.onTemplatesVisibilityChange()
      }
    })
  }

  toggleTemplatesMenu() {
    this.dispatch('templates:toggle', {
      target: this.templatesMenuTarget,
      detail: this.state.templatesVisible = !this.state.templatesVisible
    })

    if (this.state.templatesVisible) {
      this.forceBlur()
    } else {
      this.focus()
    }

    this.onTemplatesVisibilityChange()
  }

  onTemplatesVisibilityChange() {
    if (!this.hasTemplatesMenuTarget) return

    if (this.state.templatesVisible) {
      this.addClass(this.templatesIconTarget, 'text-tiger',)
    } else {
      this.removeClass(this.templatesIconTarget, 'text-tiger',)
    }
  }

  syncTemplatesMenuStatus() {
    if (!this.hasTemplatesMenuTarget) return

    const event = this.shouldOpenTemplatesMenu ? 'templates:open' : 'templates:close'

    this.dispatch(event, {
      target: this.templatesMenuTarget,
      detail: this.documentStringWithoutZeroWidthNonJoinerAndLineFeed.split('/')[1]?.trim()
    })
  }

  templatesTechnologyChanged({detail}) {
    if(this.editor) {
      this.dispatch('content:change', {
        detail: this.snapshot
      })
    }

    const { ids, names } = detail

    if(ids.length === 1 && names[0] === 'sms') {
      if(this.hasFooterTarget) {
        this.dispatch('footer:disable', {
          target: this.footerTarget
        })
      }

      this.dispatch('toolbar:disable', {
        target: this.element,
        detail: ['attachment', 'voice', 'location', 'button']
      })
    } else {
      if(this.hasFooterTarget) {
        this.dispatch('footer:enable', {
          target: this.footerTarget
        })
      }

      let tools =  ['attachment', 'voice', 'location', 'button']

      if(this.hasLocation) {
        tools = tools.filter(tool => tool !== 'attachment')
      }

      if(this.hasMediaAttachments) {
        tools = tools.filter(tool => tool !== 'location')
      }

      this.dispatch('toolbar:enable', {
        target: this.element,
        detail: tools
      })
    }

    if (!this.hasTemplatesMenuTarget) return

    this.dispatch('template:technologies:changed', {
      detail: detail.ids,
      target: this.templatesMenuTarget
    })
  }

  saveTemplateId({detail: template}) {
    if (!template.id || (template.selectionMethod === 'enter' && this.hasTextContent)) return

    this.templateIdInputTarget.value = template.id
    this.template = template

    this.editor.loadHTML(template.body)

    if(['whatsapp', 'instagram'].includes(this.technologyValue.name) && !this.technologyValue.enabled && !this.inTemplateCreationMode) {
      return this.dispatch('toolbar:show-send-button', {
        target: this.element,
      })
    }

    this.focus()
    this.onTrixContentChange()

    this.nextTick(() => this.cursor.moveToEnd())

    this.dispatch('toolbar:coupon:change', {
      target: this.element,
      detail: template.coupon
    })

    if(this.hasFooterTarget) {
      this.dispatch('footer:change', {
        target: this.footerTarget,
        detail: template.footer
      })
    }

    this.dispatch('map:restore', {
      target: this.locationContainerTarget,
      detail: {
        latitude: template.location?.latitude,
        longitude: template.location?.longitude
      }
    })

    this.dispatch('buttons:change', {
      detail: {
        buttons: template.buttons,
        element: template.element
      }
    })

    this.dispatch('attachment:set', {
      detail: template.attachment,
      target: this.mediaAttachmentToolTarget
    })
  }

  clipForwardSlashAtStartOfContent() {
    this.focus()

    const characterAtFirstPosition = this.cursor.characterAtCaret(0)

    if(characterAtFirstPosition.isForwardSlash) {
      const currentPosition = this.oldPosition

      this.editor.setSelectedRange([0, 1])
      this.editor.deleteInDirection('forward')

      this.editor.setSelectedRange(currentPosition)
    }
  }

  submit() {
    this.inputTarget.disabled = this.documentStringWithoutZeroWidthNonJoinerAndLineFeed.length === 0

    if(document.querySelectorAll("[data-uploading]").length > 0) {
      return this.showToast({
        message: this.translations.compose.submit.blocked_by_attachments_uploading,
      })
    }

    if(document.querySelectorAll("[data-upload-error]").length > 0) {
      return this.showToast({
        message: this.translations.compose.submit.blocked_by_uploaded_attachment_error,
      })
    }

    if(this.hasReachedMaxMessageLength && this.technologyValue.name !== 'sms') {
      return this.showToast({
        message: this.translations.compose.submit.blocked_by_overflow.replace('%{max_length}', this.technologyValue.max_message_length),
      })
    }

    this.dispatch('keydown:enter', {
      detail: this.snapshot
    })
  }

  insertEmoji({ detail: emoji }) {
    this.editor.insertString(emoji.native)
  }

  // Templates Tool Methods

  onAttachmentAdded({ detail: element }) {
    this.attachments.push(element)

    this.dispatch('content:change', {
      detail: this.snapshot
    })

    this.focus()
  }

  onAttachmentRemoved({ detail: element }) {
    this.attachments = Array.from(this.attachments).filter((attachment) => attachment !== element)

    this.dispatch('content:change', {
      detail: this.snapshot
    })

    this.focus()
  }

  onCouponSet({detail}) {
    this.couponIdInputTarget.value = detail.token
  }

  onCouponUnset() {
    this.couponIdInputTarget.value = ''
  }

  onSourceChannelChange({ detail }) {
    this.technologyValue = detail
  }

  onTechnologyChange({ detail }) {
    this.technologyValue = {
      ...detail.technology,
      enabled: detail.technology.name === 'sms' ? true : detail.window?.open
    }

    this.windowValue = detail.window

    if(detail.window?.open || detail.technology.name === 'sms') {
      this.enableCompose()
    } else {
      this.disable()
    }
  }

  editTemplate() {
    if(this.isEnabled) {
      return this.focus()
    }

    let url = this.editTemplateUrlValue

    if(this.template.source === 'broadcast') {
      url = url.replace(':id', `/campaigns/${this.template.id}`)
    } else if(this.template.source === 'message') {
      url = url.replace(':id', `/journeys/${this.template.id}`)
    } else {
      url = url.replace(':id', this.template.id)
    }

    const hiddenAnchor = document.createElement('a')
    hiddenAnchor.href = url
    hiddenAnchor.target = '_blank'

    hiddenAnchor.click()
  }

  // private

  enableCompose() {
    this.enable()
    this.focus()
    this.nextTick(() => this.cursor.moveToEnd())

    this.templatesIconTarget.classList.replace('bg-transparent', 'bg-white')
  }

  disable() {
    super.disable()
    this.templatesIconTarget.classList.replace('bg-white', 'bg-transparent')
  }

  onTrixClick() {
    if(this.isDisabled) {
      this.templatesIconTarget.click()
    }
  }

  dispatchInitializeEvent() {
    this.attachments = Array.from(this.element.querySelectorAll('[data-controller="editor--attached-media"]')) || []

    this.dispatch('initialize', {
      detail: this.snapshot
    })
  }

  toString() {
    return this.trixDocument.getPieces().map(piece => {
      if (piece.attachment) {
        return piece.attachment.attributes.values.caption
      }

      return piece.string
    }).join('')
  }

  onMapClick({ detail: { latitude, longitude } }) {
    this.latitudeValue = latitude
    this.longitudeValue = longitude

    this.dispatch('content:change', {
      detail: this.snapshot,
    })
  }

  unsetLocationCoordinates() {
    this.latitudeValue = null
    this.longitudeValue = null

    this.dispatch('content:change', {
      detail: this.snapshot,
    })
  }

  onFooterChange({ detail: footer }) {
    this.footerValue = footer

    this.dispatch('content:change', {
      detail: this.snapshot,
    })
  }

  saveButtonsSnapshot({ detail: types }) {
    this.buttonsSnapshotValue = types

    this.dispatch('content:change', {
      detail: this.snapshot,
    })
  }

  clearAttributesFromSnapshot() {
    this.latitudeValue = this.longitudeValue = null
    this.footerValue = null

    this.attachments.forEach(attachment => attachment.remove())
    this.mediaContainerTarget.classList.add('hidden')
    this.attachments = []
    this.buttonsSnapshotValue = null

    this.dispatch('map:clear', { target: this.locationContainerTarget })
    this.dispatch('footer:clear', { target: this.footerTarget })
    this.dispatch('buttons:clear', { target: this.element.closest('[data-controller*="compose--template"]') })
  }

  clearLocationValue() {
    this.latitudeValue = null
    this.longitudeValue = null
  }

  get snapshot() {
    const string = this.trixDocument.toString().replace(/\u200C/g, '')

    return {
      string: string.length > 1 ? this.toString() : '',
      pieces: this.trixDocument.getPieces(),
      trixSnapshot: this.editor.getSnapshot(),
      ...this.technologies.snapshot,
      html: this.trixTarget.innerHTML,
      overflow: this.inTemplateCreationMode && this.hasReachedMaxMessageLength,
      technology: this.technologyValue,
      template: this.template,
      templateChanged: this.template && this.template.plain_text.trim() !== string.trim(),
      location: this.hasLocation ? { lat: this.latitudeValue, lng: this.longitudeValue } : null,
      footer: this.footerValue,
      buttons: this.buttonsSnapshotValue,
      attachments: this.attachments,
      links: this.state.links,
    }
  }

  get technologies() {
    if (!this.hasTechnologiesTarget) return {}

    return this.application.getControllerForElementAndIdentifier(this.technologiesTarget, 'compose--technologies')
  }

  resetTargetConnected() {
    this.resetContent()
    this.templateIdInputTarget.value = ''

    this.resetTarget.remove()

    this.mediaContainerTarget.classList.add('hidden')
    this.mediaContainerListTarget.innerHTML = ''

    if(this.hasTemplatesMenuTarget && this.state.templatesVisible) {
      this.templatesIconTarget.click()
    }

    if(this.windowValue?.open || this.technologyValue.name === 'sms') {
      this.enableCompose()
    } else {
      this.disable()
    }
  }

  focusTargetConnected() {
    this.nextTick(() => this.focus(), 500)
  }

  contentLengthBeforePiece(index) {
    return this.trixDocument
      .getPieces()
      .filter((piece, position) => position < index)
      .map(piece => piece.attachment ? 1 : piece.string.length)
      .reduce((a, b) => a + b, 0)
  }

  get hasContent() {
    return this.hasTextContent || this.hasAttachments
  }

  get hasTextContent() {
    return this.documentStringWithoutZeroWidthNonJoinerAndLineFeed.length > 1
  }

  get hasAttachments() {
    return this.hasVoiceMessage || this.hasMediaAttachments || this.hasLocation
  }

  get hasVoiceMessage() {
    return this.hasVoiceContainerTarget && this.voiceContainerTarget.querySelector('audio')
  }

  get hasMediaAttachments() {
    return this.hasMediaContainerListTarget && this.mediaContainerListTarget.children.length > 0
  }

  get hasLocation() {
    return this.hasLocationContainerTarget && !this.locationContainerTarget.classList.contains('hidden')
  }

  get linkEditTool() {
    return this.element.querySelector(`[data-tool='link-edit']`)
  }

  get shouldOpenTemplatesMenu() {
    return this.trixDocument.toString().startsWith('/')
  }

  get empty() {
    return this.trixDocument.toString().length === 1
  }

  isOlderThanOneDay(date) {
    const now = new Date();
    const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);

    return date < twentyFourHoursAgo;
  }

  get hasReachedMaxMessageLength() {
    return this.trixDocument.toString().trim().length >= this.technologyValue.max_message_length
  }
}
