import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    focus: { type: Boolean, default: false },
  }

  initialize() {
    super.initialize()

    this.resetInput = this.resetInput.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.clearInputs = this.clearInputs.bind(this)

    this.isVisible = this.isVisible.bind(this)
    this.isInvisible = this.isInvisible.bind(this)

    this.show = this.show.bind(this)
    this.hide = this.hide.bind(this)

    this.enable = this.enable.bind(this)
    this.disable = this.disable.bind(this)
  }

  show(...elements) {
    if(elements.length === 0) {
      elements.push(this.element)
    }

    elements.forEach((element) => {
      element.classList.remove('hidden')
      element.removeAttribute('hidden')
    })
  }

  hide(...elements) {
    if(elements.length === 0) {
      elements.push(this.element)
    }

    elements.forEach((element) => {
      element?.classList?.add('hidden')
    })
  }

  clearInput(element = this.element) {
    element.value = ""
  }

  clearInputs(...inputs) {
    inputs.forEach(this.clearInput)
  }

  resetInput(element = this.element) {
    element.value = element.dataset.original || ""
  }

  enable(element) {
    if(typeof element === "string") {
      element = document.querySelector(element)
    }

    element.disabled = false
  }

  disable(element, { clear = false } = {}) {
    if(typeof element === "string") {
      element = document.querySelector(element)
    }

    element.disabled = true

    if(clear) {
      this.clearInput(element)
    }
  }

  addClass(element, ...classNames) {
    element.classList.add(...classNames)
  }

  replaceClass(element = this.element, oldClassName, newClassName) {
    element.classList.replace(oldClassName, newClassName)
  }

  removeClass(element, ...classNames) {
    element.classList.remove(...classNames)
  }

  valueOfMetaTag(name) {
    return document.querySelector(`meta[name="${name}"]`).getAttribute("value")
  }

  delayed(callback, delay = 500) {
    return setTimeout(callback, delay)
  }

  nextTick(callback, timeout = 0) {
    return setTimeout(callback, timeout)
  }

  showToast({ message = null, type = "information", autoHide = true, hideAfter = 5000 }) {
    const event = new CustomEvent("toast:show", {
      detail: { message, type, autoHide, hideAfter }
    })

    window.dispatchEvent(event)
  }

  focus(input, { moveCursorToEnd = false, moveCursorToStart = false } = {}) {
    if(typeof input === "string") {
      input = document.querySelector(input)
    }

    const originalType = input.type

    if(input.tagName === 'INPUT' && originalType !== 'text') {
      input.type = 'text'
    }

    if(moveCursorToEnd) {
      input.selectionStart = input.selectionEnd = input.value.length
    } else if(moveCursorToStart) {
      input.selectionStart = input.selectionEnd = 0
    } else {
      input.selectionStart = 0
      input.selectionEnd = 0
    }

    if(input.tagName === 'INPUT') {
      input.type = originalType
    }

    input.focus()
  }

  isVisible(element = this.element) {
    return !element.classList.contains('hidden')
  }

  isInvisible(element = this.element) {
    return element.classList.contains('hidden')
  }

  debounce(callback, delay = 500) {
    clearTimeout(this.debounceTimeout)
    this.debounceTimeout = setTimeout(callback, delay)
  }

  clearDebounce() {
    clearTimeout(this.debounceTimeout)
  }

  get translations() {
    return window.t
  }

  get visible() {
    return !this.element.classList.contains("hidden")
  }

  get invisible() {
    return this.element.classList.contains("hidden")
  }

  get online() {
    return navigator.onLine
  }

  get offline() {
    return !navigator.onLine
  }

  get userLocale() {
    return this.valueOfMetaTag("user-locale")
  }

  get onMobile() {
    return window.innerWidth < 768
  }

  get indexPage() {
    return window.location.href.endsWith("inbox")
  }
}
