import ApplicationController from '../application_controller'
import { useToastNotifiers } from "../mixins/useToastNotifiers"
import Papa from 'papaparse'

export default class extends ApplicationController {
  static targets = [
    'dragarea',
    'fileField',
    'fileIcon',
    'fileName',
    'fileSet',
    'fileSize',
    'fileUnset',
    'spinnerIcon',
    'headersInput',
    'separatorInput',
  ]

  static values = {
    file: Object
  }

  static classes = [
    'noDragging',
    'dragging',
  ]

  connect() {
    this.toggle()
    this.makeDraggable()
    this.addEventListenerToForm()

    useToastNotifiers(this)
  }

  onFileSet() {
    const file = this.fileFieldTarget.files[0]

    if (!this.isAllowedFile(file)) {
      return this.showToastInformationMessage(
        this.translations.customers.imports.form.file.unsupported,
        { autoHide: true }
      )
    }

    Papa.parse(file, {
      skipEmptyLines: true,
      complete: (results) => {
        const decoder = new TextDecoder('utf-8');

        const detectedDelimiter = results.meta.delimiter;
        console.log('Detected Delimiter:', detectedDelimiter);

        for (const row of results.data) {
          if (row.some(cell => cell.trim() !== '')) { // Check for non-empty row
            const headers = row.map(cell => cell.trim()); // Simply trim the cells
            console.log('Headers:', headers);

            this.headersInputTarget.value = headers.join(',');
            break; // Stop after finding the first non-empty row
          }
        }

        this.separatorInputTarget.value = detectedDelimiter;
      },
      error: function(error) {
        console.error('Error parsing CSV:', error);
      }
    });

    this.fileValue = {
      name: this.sanitize(file.name),
      size: this.humanFileSize(file.size)
    }

    this.updateDOM()
  }

  fileUnset() {
    this.fileValue = {}
    this.updateDOM()
  }

  updateDOM() {
    this.fileNameTarget.textContent = this.fileValue.name || ''
    this.fileSizeTarget.textContent = this.fileValue.size || ''

    this.toggle()
  }

  toggle() {
    if (this.fileValue.name) {
      this.fileSetTarget.classList.remove('hidden')
      this.fileUnsetTarget.classList.add('hidden')
    } else {
      this.fileSetTarget.classList.add('hidden')
      this.fileUnsetTarget.classList.remove('hidden')
    }
  }

  makeDraggable() {
    document.addEventListener('dragend', this.dragEnded.bind(this))
    document.addEventListener('dragover', (e) => {
      e.preventDefault()

      this.dragareaTarget.classList.add(this.draggingClass)
      this.dragareaTarget.classList.remove(this.noDraggingClass)
    })

    // Handle file drop on drag area
    this.dragareaTarget.addEventListener('drop', (e) => {
      e.preventDefault()

      this.dragEnded.bind(this)(e)

      if (e.dataTransfer.files.length == 0) {
        return
      }

      this.fileFieldTarget.files = e.dataTransfer.files
      this.onFileSet()
    })

    // Handle file drop on document
    document.addEventListener('drop', (e) => {
      e.preventDefault()

      this.dragEnded.bind(this)(e)
    })

    // Handle mouse out of document
    document.addEventListener('dragleave', (e) => {
      e.preventDefault()

      this.dragEnded.bind(this)(e)
    })
  }

  addEventListenerToForm() {
    this.element.addEventListener('submit', (e) => {
      this.show(this.spinnerIconTarget)
      this.hide(this.fileIconTarget)
    })
  }

  dragEnded(e) {
    e.preventDefault()

    this.dragareaTarget.classList.remove(this.draggingClass)
    this.dragareaTarget.classList.add(this.noDraggingClass)
  }

  isAllowedFile(file) {
    if (! file)  return false

    return [
      'text/csv', // CSV
      'text/plain', // TXT
    ].includes(file.type)
  }

  sanitize(name) {
    return name.replace(/[^a-z0-9\.\-\_]/gi, '_').substring(0, 20)
  }

  humanFileSize(size) {
    const i = Math.floor(Math.log(size) / Math.log(1024))
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    )
  }
}
