import LineController from "../journeys/line_controller"

export default class extends LineController {
  static targets = [
    'child',
    'bodyInput',
    'description'
  ]

  disconnect() {
    this.clear()
    super.disconnect()
  }

  onWindowSizeChange() {
    this.instance.repaintEverything()
  }

  update({ detail: newValue }) {
    this.bodyInputTarget.value = newValue
    this.descriptionTarget.innerText = newValue
  }

  repaint() {
    this.instance.repaintEverything()
  }

  clear() {
    this.instance.deleteConnection(this.rootToAnswer)
    this.instance.deleteConnection(this.answerToEndRoot)
  }

  childTargetConnected() {
    if(this.answerToEndRoot) {
      this.instance.deleteConnection(this.answerToEndRoot)
    }

    this.firstChildDrawnTo = this.childTargets[0]

    this.answerToEndRoot = this.draw(this.element.querySelector('[data-header]'), this.firstChildDrawnTo, {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint("Top"),
      lineOptions: this.lineOptions,
    })
  }

  childTargetDisconnected(child) {
    if(this.childTargets.length > 0) {
      return this.childTargetConnected()
    }

    this.instance.deleteConnection(this.answerToEndRoot)

    this.answerToEndRoot = this.draw(this.element.querySelector('[data-header]'), this.end, {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint(),
      lineOptions: this.lineOptions,
    })
  }

  make({ detail }) {
    const { root, end, index } = detail

    if(this.rootToAnswer) {
      this.instance.deleteConnection(this.rootToAnswer)
      this.instance.deleteConnection(this.answerToEndRoot)
    }

    this.end = end
    this.lineOptions = {
      ...this.flowchartConnectorLineOptions,
      options: {
        ...this.flowchartConnectorLineOptions.options,
        cornerRadius: index === 0 ? 10 : 0,
      }
    }

    this.rootToAnswer = this.draw(root, this.element, {
      fromOptions: this.blankEndpoint("Bottom"),
      toOptions: this.blankEndpoint("Top"),
      lineOptions: this.lineOptions,
    })

    if(this.childTargets.length === 0) {
      this.answerToEndRoot = this.draw(this.element.querySelector('[data-header]'), end, {
        fromOptions: this.blankEndpoint("Bottom"),
        toOptions: this.blankEndpoint(),
        lineOptions: this.lineOptions,
      })
    }
  }
}
