import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["select", "trigger", "option"]

  onSelectValueChanged({ currentTarget }) {
    this.dispatch("value-changed", {
      detail: currentTarget.value
    })
  }

  setPeriodValue({ detail }) {
    this.dispatch("select", {
      target: this.selectTarget,
      detail,
    })

    this.dispatch("menu:set-label", {
      target: this.element.querySelector("[data-controller='custom-dropdown']"),
      detail,
    })
  }

  updateTrigger({ currentTarget }) {
    this.triggerTarget.textContent = currentTarget.textContent

    this.optionTargets.forEach((option) => {
      if(option === currentTarget) {
        option.classList.add('bg-lavender-light')
      } else {
        option.classList.remove('bg-lavender-light')
      }
    })
  }
}
