import {Loader} from "@googlemaps/js-api-loader";

import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static values = {
    apiKey: String,
    latitude: String,
    longitude: String,
  }

  static targets = ["button", "map", "locateCurrentButton", "latitudeInput", "longitudeInput", "addressInput", "resetTarget", "offlineNotice", "closeButton", "placeIdInput"]

  initialize() {
    this.onMapClick = this.onMapClick.bind(this)
    this.userLocated = this.userLocated.bind(this)
    this.userLocatedError = this.userLocatedError.bind(this)

    this.onNetworkConnection = this.onNetworkConnection.bind(this)
    this.onNetworkDisconnection = this.onNetworkDisconnection.bind(this)
  }

  connect() {
    this.loader = new Loader({
      apiKey: this.apiKeyValue,
      version: "weekly",
      libraries: ["places"]
    });

    window.addEventListener("online", this.onNetworkConnection)
    window.addEventListener("offline", this.onNetworkDisconnection)

    if(this.online) {
      this.loadMap()
    } else {
      this.onNetworkDisconnection()
    }
  }

  disconnect() {
    window.removeEventListener("online", this.onNetworkConnection)
    window.removeEventListener("offline", this.onNetworkDisconnection)
  }

  onNetworkConnection() {
    this.loadMap()

    if(this.visible) {
      this.inputs.forEach((input) => {
        input.disabled = false
      })
    }

    super.show(this.mapTarget)
    super.hide(this.offlineNoticeTarget)
  }

  onNetworkDisconnection() {
    if(this.visible) {
      this.inputs.forEach((input) => {
        input.disabled = true
      })
    }

    super.hide(this.mapTarget)
    super.show(this.offlineNoticeTarget)
  }

  loadMap() {
    if(this.offline) return

    this.loader.load().then((google) => {
      this.google = google
      this.map = new google.maps.Map(this.mapTarget, this.mapOptions);
      this.geocoder = new google.maps.Geocoder();
      this.marker = new this.google.maps.Marker({ map: this.map })

      this.centerToUserLocation()
      this.map.addListener("click", this.onMapClick)
    });
  }

  onMapClick(e) {
    if(this.offline) return

    this.map.panTo(e.latLng);
    this.marker.setPosition(e.latLng);

    this.latitudeInputTarget.value = e.latLng.lat()
    this.longitudeInputTarget.value = e.latLng.lng()

    this.geocoder.geocode({ location: e.latLng }, (results, status) => {
      this.addressInputTarget.value = results[0].formatted_address
      this.placeIdInputTarget.value = results[0].place_id
    })

    this.nextTick(() => {
      this.dispatch('click', {
        detail: {
          latitude: e.latLng.lat(),
          longitude: e.latLng.lng()
        }
      })
    })
  }

  centerToUserLocation() {
    const currentLocation = new this.google.maps.LatLng(this.latitudeValue, this.longitudeValue);

    this.onMapClick({latLng: currentLocation})
  }

  shareCurrentLocation() {
    if(!navigator.geolocation || this.offline) return

    const options = {
      enableHighAccuracy: true
    }

    navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => {
      if(state === 'granted' || state === 'prompt') {
        return navigator.geolocation.getCurrentPosition(this.userLocated, this.userLocatedError, options)
      }

      this.showToast({
        message: this.translations.compose.tools.location.permission_state.denied,
        hideAfter: 7000
      })
    })
  }

  userLocated(position) {
    const {latitude, longitude} = position.coords

    this.panTo(latitude, longitude)

    this.dispatch('click', {
      detail: {
        latitude,
        longitude
      }
    })
  }

  userLocatedError(error) {
    const message = error.code === error.PERMISSION_DENIED ? 'denied' : 'timeout'

    this.showToast({
      message: this.translations.compose.tools.location.permission_state[message],
      hideAfter: 7000
    })
  }

  show() {
    if(this.marker) {
      this.centerToUserLocation()
      this.inputs.forEach(super.enable)
    }

    this.dispatch('visible')

    super.show()
  }

  hide() {
    super.hide()
    this.reset()

    this.dispatch('hidden')
  }

  disable() {
    this.inputs.forEach(super.disable)
    this.mapTarget.classList.add("grayscale", "cursor-not-allowed", "pointer-events-none")
  }

  enable() {
    if(this.isVisible()) {
      this.inputs.forEach(super.enable)
    }

    this.mapTarget.classList.remove("grayscale", "cursor-not-allowed", "pointer-events-none")
  }

  clearAndHide() {
    this.reset()
    super.hide()
  }

  restore({ detail }) {
    this.latitudeValue = detail.latitude
    this.longitudeValue = detail.longitude

    if(!this.latitudeValue || !this.longitudeValue) {
      super.hide()
      return this.reset()
    }

    this.panTo(this.latitudeValue, this.longitudeValue)
    this.show()
  }

  panTo(latitude, longitude) {
    const currentLocation = new this.google.maps.LatLng(latitude, longitude);

    this.map.panTo(currentLocation);
    this.marker.setPosition(currentLocation);

    this.latitudeInputTarget.value = currentLocation.lat()
    this.longitudeInputTarget.value = currentLocation.lng()

    this.geocoder.geocode({location: currentLocation}, (results, status) => {
      this.addressInputTarget.value = results[0].formatted_address
      this.placeIdInputTarget.value = results[0].place_id
    })
  }

  reset() {
    if(this.offline) return

    const currentLocation = new this.google.maps.LatLng(this.latitudeValue, this.longitudeValue);

    this.map.panTo(currentLocation);
    this.marker.setPosition(currentLocation);

    this.inputs.forEach((input) => {
      input.value = ""
      input.disabled = true
    })
  }

  get inputs() {
    return [this.latitudeInputTarget, this.longitudeInputTarget, this.addressInputTarget, this.placeIdInputTarget]
  }

  get mapOptions() {
    return {
      center: {
        lat: 0,
        lng: 0
      },
      zoom: 15,
      disableDefaultUI: false,
      fullscreenControl: false,
      streetViewControl: false,
      mapTypeControl: false,
      language: this.userLocale
    }
  }
}
