import MessageController from "../step/message_controller"

import { commaSeperatedListWithAndConjunction } from '../models/sentence'

export default class extends MessageController {
  static values = {
    name: String,
    value: String,
    id: String,
    kind: String,
  }

  static targets = [
    "pane",
    "title",
    "description",
    "submit",
    "openModal",
    "propertyInput",
    "valuesInput",
    "propertiesPopover",
    "removeButton",
    "replyNotice"
  ]

  initialize() {
    this.name = this.nameValue
    this.value = this.valueValue

    this.paneSnapshot = this.paneTarget.cloneNode(true)
    super.initialize()
  }

  connect() {
    if(this.withinAnswer && this.persistedValue && !['date', 'birthday'].includes(this.kindValue)) {
      this.replyNoticeTarget.classList.remove("hidden")
    }

    super.connect()
  }

  togglePane() {
    if(this.hasUnsavedChanges) {
      return this.openModalTarget.click()
    }

    if (window.paneId) {
     return window.dispatchEvent(
        new CustomEvent("pane:alert", {detail: this.element.id})
      )
    }

    this.paneTarget.classList.toggle("-right-full")
    this.paneTarget.classList.toggle("right-0")

    this.triggerTarget.classList.toggle("halo--active")

    if(this.paneIsInvisible) return

    this.dispatch('focus', {
      target: this.element
    })

    if(!this.persistedValue) {
      this.delayed(() => {
        this.dispatch('show', {
          target: this.propertiesPopoverTarget,
        })
      }, 300)
    }
  }

  saveValue({ detail }) {
    this.name = detail.name
    this.value = detail.value
    this.id = detail.id

    if(!this.withinAnswer) return

    if(detail.text || detail.number) {
      this.replyNoticeTarget.classList.remove("hidden")

      if(!detail.value) {
        this.dispatch('reply', {
          target: this.element,
        })

        this.value = '{reply}'
      }
    } else {
      this.replyNoticeTarget.classList.add("hidden")
    }
  }

  save() {
    window.paneId = null

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")

    this.titleTarget.textContent = t.automation.properties.property.title.set.replace('%{property}', this.name)
    this.descriptionTarget.textContent = commaSeperatedListWithAndConjunction(this.value.split(','))

    this.paneSnapshot = this.paneTarget.cloneNode(true)

    this.persistedValue = true

    this.nameValue = this.name
    this.valueValue = this.value
    this.idValue = this.id

    this.propertyInputTarget.value = this.idValue
    this.valuesInputTarget.value = this.valueValue
  }

  onClickOutside({ target }) {
    if(this.paneIsInvisible || this.paneTarget.contains(target) || this.triggerTarget === target) return

    if(this.hasUnsavedChanges) {
      return this.openModalTarget.click()
    }

    if(!this.persistedValue) {
      this.dropzone.remove()
      this.element.parentElement.remove()
      this.element.remove()
      return
    }

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")
  }

  abortChanges() {
    if(!this.persistedValue) {
      this.dropzone.remove()
      this.element.parentElement.remove()
      this.element.remove()
      return
    }

    this.paneTarget.classList.replace("right-0", "-right-full")
    this.triggerTarget.classList.remove("halo--active")

    this.name = this.nameValue
    this.value = this.valueValue

    this.delayed(() => {
      this.paneTarget.replaceWith(this.paneSnapshot.cloneNode(true))

      this.paneTarget.querySelector('input.form-control').remove()
    }, 300)
  }

  remove() {
    this.dropzone.remove()

    if (this.hasRemovedInputTarget) {
      this.removedInputTarget.disabled = false

      document
        .getElementById("drawing_container")
        .appendChild(this.removedInputTarget)
    }

    this.element.remove()
  }

  persistedValueChanged() {
    if(this.persistedValue) {
      this.removeButtonTarget.classList.remove("hidden")
    }
  }

  get paneIsInvisible() {
    return this.paneTarget.classList.contains("-right-full")
  }

  get paneIsVisible() {
    return this.paneTarget.classList.contains("right-0")
  }

  get hasUnsavedChanges() {
    return this.name !== this.nameValue || this.value !== this.valueValue
  }
}
