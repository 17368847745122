import ApplicationController from '../application_controller'
import { KeyboardKey } from "../compose/models";

export default class extends ApplicationController {
  static values = {
    min: Number,
    max: Number,
  }

  initialize() {
    super.initialize()

    this.onKeydown = this.onKeydown.bind(this)
    this.onContentPaste = this.onContentPaste.bind(this)
  }

  connect() {
    super.connect()

    this.element.addEventListener('keydown', this.onKeydown)
    this.element.addEventListener('paste', this.onContentPaste)
  }

  disconnect() {
    super.disconnect()

    this.element.removeEventListener('keydown', this.onKeydown)
    this.element.removeEventListener('keydown', this.onContentPaste)
  }

  onKeydown(event) {
    const key = KeyboardKey.fromKeyboardEvent(event)

    if(key.isUpArrow) {
      event.preventDefault()

      if(!(this.maxValue && parseInt(this.element.value) >= this.maxValue)) {
        this.element.value = parseInt(this.element.value) + 1
        this.element.dispatchEvent(new Event('input'))
      }

      return
    }

    if(key.isDownArrow) {
      event.preventDefault()

      if(!(this.minValue && parseInt(this.element.value) <= this.minValue)) {
        this.element.value = parseInt(this.element.value) - 1
        this.element.dispatchEvent(new Event('input'))
      }

      return
    }

    if(
      key.isNumeric
      || key.isArrow
      || key.isBackspace
      || key.hasAnyModifier
      || key.isTab
      || key.isEnter
    ) {
      return
    }

    event.preventDefault()
  }

  onContentPaste(event) {
    const parsedClipboardData = parseInt(event.clipboardData.getData('text/plain'))

    if(isNaN(parsedClipboardData)) {
      event.preventDefault()
    }
  }
}
