import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

import { useRemove } from "../mixins/useRemove"

export default class extends Controller {
  static targets = [
    "quantity",
    "removeButton",
    "page",
    "product",
    "orButtonContainer",
    "removedConditionInput",
    "subscription",
    "click",
  ]

  static values = {
    activityFilter: { type: Boolean, default: false },
    shortLinkSelected: { type: Boolean, default: false },
    subscribedSelected: { type: Boolean, default: false },
  }

  initialize() {
    super.initialize()
    this.markAsRemoved = this.markAsRemoved.bind(this)
    this.previousSibling = this.element.previousElementSibling
    this.parentForm = this.element.closest("form")
  }

  connect() {
    if (this.shortLinkSelectedValue) {
      this.hidePageFilters()
    }

    if (this.subscribedSelectedValue) {
      this.hidePageFilters()
      this.productTarget.classList.add("hidden")
    }

    useRemove(this, {
      afterRemove: this.markAsRemoved,
    })
  }

  disconnect() {
    if (this.previousSibling) {
      this.dispatch("removed", {
        target: this.previousSibling,
      })
    }
  }

  onSelectChange({ currentTarget }) {
    if (this.isCharacteristic) return

    this.toggleQuantityFilters(currentTarget.dataset)
    this.togglePageFilters(currentTarget.dataset)
    this.toggleSubscriptionFilters(currentTarget.dataset)
    this.toggleShortLinkFilters(currentTarget.dataset)

    if (this.hasProductTarget) {
      this.toggleProductFilters(currentTarget.dataset)
    }
  }

  showORButton() {
    if (!this.element.nextElementSibling) {
      this.orButtonContainerTarget.classList.remove("hidden")
    }
  }

  getProductFilters({ currentTarget }) {
    get(currentTarget.dataset.url, {
      responseKind: "turbo-stream",
    })
  }

  replaceDropdown({ currentTarget }) {
    if("active" in currentTarget.dataset) return

    get(currentTarget.dataset.url, {responseKind: "turbo-stream",})
  }

  // private

  get isActivity() {
    return this.activityFilterValue
  }

  get isCharacteristic() {
    return !this.isActivity
  }

  markAsRemoved() {
    if (this.hasRemovedConditionInputTarget) {
      this.removedConditionInputTarget.disabled = false
      this.parentForm.appendChild(this.removedConditionInputTarget)
    }
  }

  toggleQuantityFilters(targetDataset) {
    if ("quantifiable" in targetDataset) {
      this.quantityTarget.classList.remove("hidden")
      return
    }

    this.quantityTarget.classList.add("hidden")
  }

  toggleProductFilters(targetDataset) {
    if ("shopping" in targetDataset || "custom" in targetDataset) {
      return this.productTarget.classList.remove("hidden")
    }

    this.productTarget.classList.add("hidden")
  }

  togglePageFilters(targetDataset) {
    if ("page" in targetDataset) {
      this.showPageFilters()
      return
    }

    this.hidePageFilters()
  }

  toggleSubscriptionFilters(targetDataset) {
    if("subscription" in targetDataset) {
      Array.from(this.subscriptionTarget.querySelectorAll('input, select'))
        .filter(input => !input.closest('[data-segments--lists-target="template"]'))
        .forEach(input => input.disabled = false)

      return this.subscriptionTarget.classList.remove("hidden")
    }

    Array.from(this.subscriptionTarget.querySelectorAll('input, select'))
      .filter(input => !input.closest('[data--segments--lists-target="template"]'))
      .forEach(input => input.disabled = true)

    this.subscriptionTarget.classList.add("hidden")
  }

  toggleShortLinkFilters(targetDataset) {
    if('shortLink' in targetDataset) {
      Array.from(this.clickTarget.querySelectorAll('input, select'))
        .filter(input => !input.closest('[data-segments--lists-target="template"]'))
        .forEach(input => input.disabled = false)

      return this.clickTarget.classList.remove("hidden")
    }


    Array.from(this.clickTarget.querySelectorAll('input, select'))
      .filter(input => !input.closest('[data-segments--lists-target="template"]'))
      .forEach(input => input.disabled = true)

    this.clickTarget.classList.add("hidden")
  }

  showPageFilters() {
    this.pageTarget.classList.remove("hidden")

    Array.from(this.pageTarget.getElementsByTagName("input")).forEach(
      (input) => (input.disabled = false)
    )

    Array.from(this.pageTarget.getElementsByTagName("select")).forEach(
      (select) => (select.disabled = false)
    )
  }

  hidePageFilters() {
    this.pageTarget.classList.add("hidden")
    this.pageTarget.querySelectorAll('input, select').forEach(input => input.disabled = true)
  }
}
