import ApplicationController from '../application_controller'

export default class extends ApplicationController {
  static values = {
    searchUrl: String,
    propertyId: String,
    propertyKind: String,
    propertyName: String,
  }

  static targets = [
    'item',
    'placeholder',
    'placeholderIcon',
    'placeholderDescription',
    'textInput',
    'numberInput',
    'list',
    'tags',
    'submit',
    'dateFormatSection',
    'dateFormatItem',
    'dateFormatOption',
    'dateFormatPlaceholder',
  ]

  selectDateOption({ currentTarget }) {
    currentTarget.classList.add('bg-lavender-light')

    this.dateFormatOptionTargets.forEach(item => {
      if(item !== currentTarget) {
        item.classList.remove('bg-lavender-light')
      }
    })

    this.dateFormatPlaceholderTarget.textContent = currentTarget.dataset.format

    this.dispatch('changed', {
      target: this.element,
      detail: {
        id: this.propertyIdValue,
        value: currentTarget.dataset.format,
        name: this.propertyNameValue,
        text: false,
        number: false,
        date: true,
      }
    })
  }

  select({ currentTarget }) {
    if(currentTarget.dataset.id === this.propertyIdValue) {
      return this.focusInput()
    }

    currentTarget.classList.add('bg-lavender-light')

    this.itemTargets.forEach(item => {
      if(item !== currentTarget) {
        item.classList.remove('bg-lavender-light')
      }
    })

    this.placeholderTarget.classList.add('gap-x-2')

    this.placeholderIconTarget.innerHTML = currentTarget.querySelector('[data-icon]').innerHTML
    this.placeholderDescriptionTarget.textContent = currentTarget.querySelector('[data-description]').innerText

    this.propertyIdValue = currentTarget.dataset.id
    this.propertyKindValue = currentTarget.dataset.kind
    this.propertyNameValue = currentTarget.querySelector('[data-description]').innerText

    this.showInputForPropertyType(currentTarget.dataset.kind)

    if(this.dateTypes.includes(currentTarget.dataset.kind)) {
      this.show(this.dateFormatSectionTarget)
      this.submitTarget.disabled = false

      this.nextTick(() => {
        this.dateFormatItemTargets[0].querySelector('input[type="radio"]').checked = true
        this.dateFormatChanged({ target: this.dateFormatItemTargets[0].querySelector('input[type="radio"]') })
      })
    } else {
      this.hide(this.dateFormatSectionTarget)
    }
  }

  showInputForPropertyType(kind) {
    this.submitTarget.disabled = true

    this.textInputTarget.value = ''
    this.numberInputTarget.value = ''

    if(this.textTypes.includes(kind)) {
      this.hide(this.listTarget, this.tagsTarget, this.numberInputTarget)
      this.show(this.textInputTarget)

      this.textInputTarget.placeholder = t.properties.fields[kind]

      this.focus(this.textInputTarget)

      this.dispatch('clear', { target: this.listTarget })
      this.dispatch('clear', { target: this.tagsTarget })
    } else if(kind === 'list') {
      this.hide(this.textInputTarget, this.numberInputTarget, this.tagsTarget)
      this.show(this.listTarget)

      this.dispatch('focus', { target: this.listTarget })

      this.dispatch('clear', { target: this.tagsTarget })
    } else if(kind === 'tags') {
      this.hide(this.textInputTarget, this.numberInputTarget, this.listTarget)
      this.show(this.tagsTarget)

      this.dispatch('focus', { target: this.tagsTarget })
      this.dispatch('clear', { target: this.listTarget })
    } else if(this.dateTypes.includes(kind)) {
      this.hide(this.textInputTarget, this.numberInputTarget, this.tagsTarget, this.listTarget)
      this.dispatch('clear', { target: this.tagsTarget })
      this.dispatch('clear', { target: this.listTarget })
    } else {
      this.hide(this.listTarget, this.tagsTarget, this.numberInputTarget)
      this.show(this.textInputTarget)

      this.textInputTarget.placeholder = t.properties.fields[kind]

      this.focus(this.textInputTarget)

      this.textInputTarget.value = ''

      this.dispatch('clear', { target: this.listTarget })
      this.dispatch('clear', { target: this.tagsTarget })
    }

    this.dispatch('changed', {
      target: this.element,
      detail: {
        id: this.propertyIdValue,
        value: '',
        name: this.propertyNameValue,
        text: this.textTypes.includes(kind) ,
        number: kind === 'number'
      }
    })
  }

  setValueToDefaultReplyTag() {
    if(this.textTypes.includes(this.propertyKindValue)) {
      this.textInputTarget.value = '{reply}'

      this.saveSnapshot({ currentTarget: this.textInputTarget })
    } else {
      this.numberInputTarget.value = '{reply}'

      this.saveSnapshot({ currentTarget: this.numberInputTarget })
    }
  }

  focusInput() {
    if(this.textTypes.includes(this.propertyKindValue)) {
      this.focus(this.textInputTarget, { moveCursorToEnd: true })
    } else if(this.propertyKindValue === 'list') {
      this.dispatch('focus', { target: this.listTarget })
    } else if(this.propertyKindValue === 'tags') {
      this.dispatch('focus', { target: this.tagsTarget })
    } else {
      this.focus(this.numberInputTarget, { moveCursorToEnd: true })
    }
  }

  saveSnapshot({ currentTarget }) {
    this.dispatch('changed', {
      target: this.element,
      detail: {
        id: this.propertyIdValue,
        value: currentTarget.value,
        name: this.propertyNameValue,
        text: this.textTypes.includes(this.propertyKindValue),
        number: this.propertyKindValue === 'number',
        date: this.dateTypes.includes(this.propertyKindValue),
      }
    })

    this.submitTarget.disabled = !currentTarget.value
  }

  setListValues({ detail: listIds }) {
    this.dispatch('changed', {
      target: this.element,
      detail: {
        id: this.propertyIdValue,
        value: listIds.join(','),
        name: this.propertyNameValue
      }
    })

    this.submitTarget.disabled = listIds.length === 0
  }

  saveAndClose({ currentTarget }) {
    if(!currentTarget.value) return

    this.dispatch('changed', {
      target: this.element,
      detail: {
        id: this.propertyIdValue,
        value: currentTarget.value,
        name: this.propertyNameValue
      }
    })

    this.submitTarget.click()
  }

  dateFormatChanged({ target }) {
    this.dateFormatItemTargets.forEach((item) => {
      if(item.contains(target)) {
        item.querySelector('[data-main]').classList.remove('text-night-40', 'pointer-events-none')
        item.querySelectorAll('[data-main] input').forEach(this.enable)
      } else {
        item.querySelector('[data-main]').classList.add('text-night-40', 'pointer-events-none')
        item.querySelectorAll('[data-main] input').forEach(this.disable)
      }
    })

    this.dispatch('changed', {
      target: this.element,
      detail: {
        id: this.propertyIdValue,
        value: t.automation.properties.property.date_format[target.value],
        name: this.propertyNameValue,
        text: false,
        number: false,
        date: true,
      }
    })

    this.submitTarget.disabled = target.value === 'specific'
  }

  get textTypes() {
    return ['text', 'url', 'company', 'gender', 'number']
  }

  get arrayTypes() {
    return ['list', 'tags']
  }

  get dateTypes() {
    return ['date', 'birthday']
  }
}
