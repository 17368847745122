// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import {
  Modal,
  Tabs,
  Popover,
  Slideover,
  Alert,
  Toggle
} from "tailwindcss-stimulus-components"
import StimulusSlimSelect from "stimulus-slimselect"

import PeriodicitySelect from "../../components/filter/periodicity_select_component/periodicity_select_controller"
import ToastController from "../../components/toast_component/toast_controller";

import { Application, defaultSchema } from 'stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const customSchema = {
  ...defaultSchema,
  keyMappings: {
    ...defaultSchema.keyMappings,
    del: 'Backspace'
  },
}

const application = Application.start(document.documentElement, customSchema)
const controllers = import.meta.glob('./**/*_controller.js', { eager: true })
registerControllers(application, controllers)

application.register("tabs", Tabs)
application.register("popover", Popover)
application.register("slideover", Slideover)
application.register("alert", Alert)
application.register("slimselect", StimulusSlimSelect)
application.register("periodicity-select", PeriodicitySelect)
application.register("toast", ToastController)
