import { Application } from '@hotwired/stimulus'
import Carousel from 'stimulus-carousel'

const application = Application.start()
application.register('carousel', Carousel)

export default class extends Carousel {
  connect() {
    super.connect()

    // The swiper instance.
    this.swiper

    // Default options for every carousels.
    this.defaultOptions
  }

  // You can set default options in this getter.
  get defaultOptions() {
    return {}
  }
}
